<template>
  <v-expansion-panels
      :class="{'mobile-Devices': $vuetify.breakpoint.mobile, 'large-Devices': !$vuetify.breakpoint.mobile}"
      :value="value"
  >
    <v-expansion-panel
        class="accordion_collapse"
        v-click-outside="onClickOutside"
    >
      <v-expansion-panel-header>
        <h3>{{$t("accordion.manageAccount")}}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-divider></v-divider>

        <v-list dense>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item
                v-for="(item, i) in signUpItems"
                :key="`${item.id}_${i}`"
                :to="item.route"
                @click="onClick(item.onClick)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-for="(item, i) in loggedItems"
                :key="`${item.id}_${i}`"
                :to="item.route"
                @click="onClick(item.onClick)"
                :disabled="disableMenus(item.id)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";

Vue.directive("click-out", {
  bind(el, binding, vnode) {
    el.clickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutside);
  },

  unbind(el) {
    document.body.removeEventListener("click", el.clickOutside);
  },
});
export default {
  name: 'AccordionComponent',

  data() {
    return {
      value: null,
      selectedItem: null,
      session: false,
      menu: false,
    };
  },

  created() {
    this.value = 1;
  },

  computed: {
    ...mapGetters(["menuItems", "isLoggedIn", "sessionData", "currentUserType"]),

    loggedItems() {
      const self = this;
      let loggeItems = self.menuItems.filter((item) => item.requireAuth === true);

      return loggeItems;
    },

    signUpItems() {
      const self = this;
      let signUpItems = self.menuItems.filter((item) => item.requireAuth === false);

      return signUpItems;
    },
  },
  methods: {
    ...mapActions(["signOut"]),

    onClickOutside() {
      this.value = !this.value;
    },

    onClick(function_name) {
      if (!function_name) return;

      const self = this;
      self.menu = false;
      self[function_name]();

      // if (function_name == "signOut") {
      //    setTimeout(() => {
      //    self.$router.push('/signin')
      //  }, 500);
      // }
    },

    disableMenus(menuId) {
      const userType = this.currentUserType.key;

      let isUser = userType === "tenant_user" && ![10].includes(menuId);
      let disableAdminListMenu = userType !== 'master_admin' && [8].includes(menuId);
      let accountInactive = [9].includes(menuId) && this.$store.getters.allSubscriptionsAreInactive;


      return accountInactive || isUser || disableAdminListMenu;
    }
  },
};
</script>

<style scoped>
.accordion_collapse {
  cursor: pointer;
  border: 1px solid #ebebeb;
}
.mobile-Devices {
  width: 85%;
  margin-top: 20px
}
.large-Devices {
  width: 40%;
  margin-top: 20px
}
h3 {
  color: #666666;
}
</style>

